const initialData = {
  name: "NFTCollection",
  description: "My NFT collection",
  width: 1000,
  height: 1000,
  date: new Date().toISOString().split("T")[0],
  banner: "",
};

export default initialData;
