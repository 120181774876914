const iLayers = {
  selectedLayer: 0,
  items: [
    {
      name: "Layer",
      position: new Date().getTime(),
      images: [],
    },
  ],
};

export default iLayers;
